.rank-1 {
  color: #ffd700;
}

.rank-2 {
  color: #c0c0c0;
}

.rank-3 {
  color: #cd7f32;
}

.rank-1,
.rank-2,
.rank-3 {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
}

.rank {
  font-size: 18px;
  width: 150px;
  text-align: center;
}

.season-details {
  margin-top: 25px;
}

.loading-container {
  padding: 50px;
  background-color: #efdab1;
  border: 1px solid #d3bd92;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}

.filter-container {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #51423d;
  padding: 0 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #2d1f11;
  border-radius: 0.25rem;
}

.filter-container .btn-group,
.filter-container .input-group {
  margin: 15px 0;
}

table.table-hs {
  background-color: #efdab1;
  color: #545454;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.table.table-hs td,
.table.table-hs th {
  border-top: 1px solid #d3bd92;
}

.table.table-hs thead {
  background-color: #51423d;
  border: 1px solid #111;
}

.table.table-hs thead tr {
  border: none;
}

.table.table-hs thead th {
  color: #fff;
  text-shadow: 1px 1px #000;
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
}

.table-striped.table-hs tbody tr:nth-of-type(odd) {
  background-color: #f4e5c6;
}

.table-hs.table-hover tbody tr:hover {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.table-hs tr {
  border: 1px solid #d3bd92;
}
