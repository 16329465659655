body {
  background-color: #e7d4a9;
  color: #51423d;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control:focus {
  border-color: rgb(206, 212, 218);
  outline: 0;
  box-shadow: none !important;
}

.btn-primary {
  background-color: #913c1e;
  border-color: #241c1a;
  color: #f1ca43;
  box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:hover,
.btn-primary:active {
  background-color: #b14a25;
  border-color: #241c1a;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #b14a25;
  border-color: #241c1a;
  opacity: 1;
  color: #fff;
}

.btn-secondary {
  background-color: #3d312d;
  border-color: #241c1a;
  box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: #514239;
  border-color: #241c1a;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #514239;
  border-color: #241c1a;
  opacity: 1;
  color: #f1ca43;
  font-weight: bold;
}

.form-control,
.form-control:focus {
  color: #51423d;
}

select.form-control,
select.form-control:focus,
input.form-control,
input.form-control:focus {
  padding: 6px 10px;
  line-height: 38px;
  background: linear-gradient(#f2eee8, #fffcf6);
  border: 0.125em solid #f9f7f0;
  box-shadow: 0 0.15rem 0.15rem rgba(156, 118, 70, 0.3);
}

.input-group-text {
  color: #f1ca43;
  border: 1px solid #241c1a;
  background-color: #514239;
}

h1 {
  color: #4c4565;
}

.input-group > .custom-select.has-clear:not(:last-child),
.input-group > .form-control.has-clear:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn-clear {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  margin-left: -38px;
  z-index: 9999;
  height: 38px;
  width: 38px;
  padding: 0;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.btn-clear:hover {
  color: rgba(0, 0, 0, 0.5);
}
